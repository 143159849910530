import { Cancel, CheckCircle, Search } from '@mui/icons-material';
import { Box, CircularProgress, InputAdornment, Tooltip } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import {
  CustomFilterIcon,
  GridFilterCustom,
} from '@/components/common/GridFilterCustom';
import { EmptyReport } from '@/components/common/GridFilterCustom/GridStyledComponents';
import { StatusChip } from '@/components/styled/Chip';
import { TypographyElipsis } from '@/components/styled/ElipsisTypography';
import { ROUTES } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { ToastService } from '@/services';
import { getAdminRole } from '@/store/selectors';
import {
  getUserFilteredCount,
  getUserLoader,
  getUsersList,
  getUserTotalCount,
} from '@/store/selectors/users';
import { getFilteredUsers, getMoreUsers, getUsers } from '@/store/thunks';
import { theme } from '@/theme';
import { UserStatusColor } from '@/theme/theming/statusesTheme';
import {
  ClientUser,
  EClientUserStatus,
  ESortDirection,
  EUserRole,
} from '@/types';

import NoSearch from '../../../../assets/pictures/Search.svg';
import {
  SearchInput,
  TableContent,
  TablePreHeader,
  TableWrapper,
  TitlePreHeader,
} from '../../styled/TableWrapper';
import { sortedFields, TSortable } from './constants/sortUsers';

// eslint-disable-next-line react/no-multi-comp
const NoFilterResult = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="10px"
    >
      <img src={NoSearch} />
      <EmptyReport>No user found</EmptyReport>
    </Box>
  );
};

// eslint-disable-next-line react/no-multi-comp
const TableLoader = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="10px"
    >
      <CircularProgress />
    </Box>
  );
};

const slotProps = {
  tooltip: {
    sx: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[800],
      borderColor: theme.palette.grey[500],
      padding: '2px 6px',
      fontWeight: 400,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
  },
};

const protectedColumns = ['email', 'mobilePhone'];

const rowsMapper = (users: Array<ClientUser>) =>
  users.map((user) => ({
    id: user.id,
    fullName:
      user?.firstName && user?.lastName
        ? `${user?.firstName} ${user?.lastName}`
        : user?.firstName || '',
    username: user.username,
    mobilePhone: user.mobilePhone,
    email: user?.email,
    isEmailVerified: user?.isEmailVerified,
    geocachingUsername: user?.geocachingUsername,
    lastLocationName: user?.lastLocationName,
    createdAt: user.createdAt,
    status: user.status,
    activeBans: user.activeBans,
  }));

const createFilterObject = (filter?: GridFilterModel, search?: string) => {
  if (search) {
    return {
      search,
    };
  }

  const filterItem = filter?.items?.[0];

  if (filterItem) {
    const { field, value } = filterItem;

    switch (field) {
      case 'status':
        return {
          status: value,
        };
      case 'email':
        return {
          isEmailVerified: value,
        };
      case 'geocachingUsername':
        return {
          isGeocachingConnected: value,
        };
      default:
        return {};
    }
  }

  return {};
};

const columnsData: GridColDef[] = [
  {
    field: 'fullName',
    headerName: 'Name',
    flex: 1,
    sortable: true,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: 'username',
    headerName: 'Nickname',
    flex: 1,
    sortable: true,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: 'mobilePhone',
    headerName: 'Phone Number',
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1.5,
    sortable: true,
    filterable: true,
    disableColumnMenu: false,
    renderCell: ({ row }) => {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Tooltip
            title={row.email}
            placement="bottom-end"
            slotProps={slotProps}
          >
            <TypographyElipsis>{row.email}</TypographyElipsis>
          </Tooltip>
          {row.emailVerified ? (
            <CheckCircle color="success" />
          ) : (
            <Cancel color={'error'} />
          )}
        </Box>
      );
    },

    type: 'singleSelect',
    valueOptions: [
      {
        value: true,
        label: 'Verified',
      },
      {
        value: false,
        label: 'Not Verified',
      },
    ],
  },
  {
    field: 'lastLocationName',
    headerName: 'Region',
    flex: 1,
    sortable: true,
    filterable: false,
    disableColumnMenu: true,
    valueFormatter: ({ value }) => {
      return value || 'N/A';
    },
  },
  {
    field: 'geocachingUsername',
    headerName: 'Geocaching Account',
    flex: 1,
    sortable: true,
    filterable: true,
    disableColumnMenu: false,
    type: 'singleSelect',
    valueFormatter: ({ value }) => {
      return value || 'N/A';
    },
    valueOptions: [
      {
        value: true,
        label: 'Yes',
      },
      {
        value: false,
        label: 'No',
      },
    ],
  },
  {
    field: 'createdAt',
    headerName: 'Joined At',
    flex: 1,
    sortable: true,
    filterable: false,
    valueFormatter: (date) => {
      return format(parseISO(date.value), 'dd/MM/yy');
    },
    disableColumnMenu: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 170,
    sortable: false,
    filterable: true,
    renderCell: ({ row }) => {
      return (
        <StatusChip
          sx={{
            marginLeft: 0,
            backgroundColor: UserStatusColor[row.status as EClientUserStatus],
          }}
          label={
            row.status === EClientUserStatus.BAN ? 'Banned From App' : 'Active'
          }
        />
      );
    },
    type: 'singleSelect',
    valueOptions: Object.values(EClientUserStatus).map((val) => ({
      value: val,
      label: val === EClientUserStatus.BAN ? 'Banned From App' : 'Active',
    })),
  },
];

// eslint-disable-next-line react/no-multi-comp
export const Users = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allUsers = useAppSelector(getUsersList);
  const totalCount = useAppSelector(getUserTotalCount);
  const filteredCount = useAppSelector(getUserFilteredCount);
  const userRole = useAppSelector(getAdminRole);

  const [users, setUsers] = useState(allUsers);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [filtering, setFiltering] = useState<GridFilterModel>();
  const [searchParams, setSearchParams] = useState<string>('');
  const userLoading = useAppSelector(getUserLoader);

  const sortParams = useMemo(() => {
    if (sorting?.length && sorting[0].sort) {
      return {
        orderBy: sortedFields[sorting[0].field as TSortable],
        orderDirection: sorting[0].sort.toUpperCase() as ESortDirection,
      };
    }
    return {};
  }, [sorting]);

  const filterParams = useMemo(() => {
    if (filtering?.items.length || searchParams) {
      return createFilterObject(filtering, searchParams);
    }
    return {};
  }, [filtering, searchParams]);

  const pageData = useMemo(() => {
    return users?.slice(
      paginationModel.page * paginationModel.pageSize,
      paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
    );
  }, [users, paginationModel]);

  const columns = useMemo(() => {
    if (userRole !== EUserRole.SUPER_ADMIN) {
      return columnsData.filter(
        (column) => !protectedColumns.includes(column.field)
      );
    }

    return columnsData;
  }, [userRole]);

  useEffect(() => {
    dispatch(
      getUsers({
        limit: 10,
        offset: 0,
      })
    )
      .unwrap()
      .then((userList) => {
        setUsers(userList?.data || []);
      })
      .catch((err) => {
        ToastService.error(err.message);
      });
  }, [dispatch]);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSorting(sortModel);
      const sorts =
        sortModel?.length && sortModel[0].sort
          ? {
              orderBy: sortedFields[sortModel[0].field as TSortable],
              orderDirection: sortModel[0].sort.toUpperCase() as ESortDirection,
            }
          : {};
      dispatch(
        getUsers({
          offset: 0,
          limit: users?.length || 0,
          ...sorts,
          ...filterParams,
        })
      )
        .unwrap()
        .then((responseUser) => {
          setUsers(responseUser.data);
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    },
    [dispatch, users?.length, filterParams]
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFiltering(filterModel);
      const filter = filterModel?.items.length
        ? createFilterObject(filterModel, '')
        : {};

      dispatch(
        getFilteredUsers({
          offset: 0,
          limit: paginationModel.pageSize,
          ...sortParams,
          ...filter,
        })
      )
        .unwrap()
        .then((responseUser) => {
          setUsers(responseUser.data);
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    },
    [dispatch, paginationModel.pageSize, sortParams]
  );

  const handlePaginationModel = useCallback(
    (pagination: GridPaginationModel) => {
      setPaginationModel(pagination);
      if (
        users?.length &&
        (pagination.page + 1) * pagination.pageSize > users?.length
      ) {
        dispatch(
          getMoreUsers({
            offset: users.length,
            limit: pagination.pageSize,
            ...sortParams,
            ...filterParams,
          })
        )
          .unwrap()
          .then((responseUser) => {
            const usersCount = users.length + (responseUser?.data.length || 0);
            if (usersCount < (pagination.page + 1) * pagination.pageSize) {
              setPaginationModel({
                ...pagination,
                page: Math.trunc(usersCount / pagination.pageSize),
              });
            }
            setUsers((usersList) =>
              usersList && responseUser
                ? [...usersList, ...responseUser.data]
                : []
            );
          })
          .catch((err) => {
            ToastService.error(err.message);
          });
      }
    },
    [dispatch, filterParams, sortParams, users?.length]
  );

  const search = useCallback(
    (searchStr: string) => {
      setSearchParams(searchStr);
      if (users) {
        if (!searchStr) {
          setUsers(allUsers);
        } else {
          dispatch(
            getFilteredUsers({
              limit: 10,
              offset: 0,
              search: searchStr,
              ...sortParams,
            })
          )
            .unwrap()
            .then((responseUser) => {
              setUsers(responseUser.data);
            })
            .catch((err) => {
              ToastService.error(err.message);
            });
        }
      }
    },
    [allUsers, dispatch, sortParams, users]
  );

  return (
    <TableWrapper>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <TablePreHeader width="100%">
        <TitlePreHeader>Users</TitlePreHeader>
      </TablePreHeader>
      <TableContent>
        {allUsers?.length ? (
          <Box>
            <SearchInput
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              id="search-admin"
              size="small"
              placeholder="Search..."
              variant="standard"
              type="search"
              onChange={(e) => search(e.target.value)}
            />
          </Box>
        ) : null}
        <div
          style={{
            width: '100%',
            height: users?.length && !userLoading ? 'unset' : 250,
          }}
        >
          <DataGrid
            rows={rowsMapper(pageData || [])}
            columns={columns}
            slots={{
              columnMenu: GridFilterCustom,
              noResultsOverlay: NoFilterResult,
              loadingOverlay: TableLoader,
              columnMenuIcon: CustomFilterIcon,
            }}
            rowCount={
              (filterParams.search || filterParams.status) && filteredCount
                ? filteredCount
                : totalCount || 0
            }
            pageSizeOptions={[10, 25, 50]}
            onRowClick={({ row }) =>
              navigate(`${ROUTES.DASHBOARD.USERS}/${row.id}`)
            }
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={handlePaginationModel}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            sortModel={sorting}
            filterMode="server"
            filterModel={filtering}
            onFilterModelChange={handleFilterModelChange}
            loading={userLoading}
            sx={{
              '.MuiDataGrid-filterIcon': {
                display: 'none',
              },
              [`.MuiDataGrid-columnHeader[data-field=${
                filtering?.items.length && filtering?.items[0].value
                  ? filtering?.items[0].field
                  : ''
              }]`]: {
                '.MuiDataGrid-menuIcon': {
                  display: 'flex',
                  visibility: 'unset',
                  marginRight: '0',
                  button: {
                    color: 'rgba(0, 0, 0, 0.7)',
                    position: 'absolute',
                    right: 0,
                  },
                },
              },
            }}
          />
        </div>
      </TableContent>
    </TableWrapper>
  );
};
