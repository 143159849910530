import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField } from '@mui/material';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { InnerLink } from '@/components/common';
import { ROUTES } from '@/constants';
import { useAppDispatch } from '@/hooks';
import { forgotPassword } from '@/store/thunks';
import { UserPassvordRecovery } from '@/types';

import { recoverSchema } from './constants';

export const RecoveryPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UserPassvordRecovery>({
    resolver: yupResolver(recoverSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<UserPassvordRecovery> = useCallback(
    (data) => {
      console.log(data);
      dispatch(forgotPassword(data.email))
        .unwrap()
        .then(() => {
          navigate(ROUTES.AUTH.RESET_NOTIFICATION);
        })
        .catch((err) => console.error(err));
    },
    [dispatch, navigate]
  );

  return (
    <>
      <Helmet>
        <title>Recovery Password</title>
      </Helmet>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          variant="standard"
          sx={{ marginBottom: 2 }}
          label="Email"
          {...register('email')}
          error={!!errors.email}
          helperText={
            errors.email?.message ||
            'Enter the email to which the account is registered'
          }
        />

        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{ marginTop: 3, marginBottom: '10px' }}
          disabled={!isValid}
        >
          Reset password
        </Button>
      </Box>
      <InnerLink to={ROUTES.AUTH.LOGIN}>
        <Button fullWidth variant="text">
          Back to login
        </Button>
      </InnerLink>
    </>
  );
};
