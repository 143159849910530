export enum ESettingKey {
  IS_REGISTRATION_AVAILABLE = 'is-registration-available',
}

export type Setting = {
  key: ESettingKey;
  value: boolean;
  updatedAt: string;
};

export type UpdateSettingPayload = {
  value: boolean;
  key: ESettingKey;
};
