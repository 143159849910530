import { ArrowBack, Check, Close } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

import { GlobalSpinner, Modal } from '@/components/common';
import { WarningBox } from '@/components/common/Modal/styled';
import { StatusChip } from '@/components/styled/Chip';
import { ConfirmButton } from '@/components/styled/ConfimModal';
import { TypographyElipsis } from '@/components/styled/ElipsisTypography';
import { ROUTES } from '@/constants';
import {
  actionConfirmation,
  ConfirmModal,
  EActionConfirm,
} from '@/constants/actionConfirm';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { ToastService } from '@/services';
import { getAdminRole, getUserById } from '@/store/selectors';
import {
  banUserInApp,
  deleteUser,
  getUserById as getUserByIdAction,
  unbanUserInApp,
} from '@/store/thunks';
import { theme } from '@/theme';
import { UserStatusColor } from '@/theme/theming/statusesTheme';
import {
  ClientUser,
  EClientUserStatus,
  EReportObject,
  EUserRole,
} from '@/types';

import NoUser from '../../../../assets/pictures/NoUser.svg';
import WarningSign from '../../../../assets/pictures/PopupBadge.svg';
import { TableWrapper } from '../../styled/TableWrapper';
import { ReportHistory } from '../ReportDetail/ReportHistory';
import {
  ActionBox,
  ActionButton,
  CancelButton,
  ColumnDetail,
  ColumnTitle,
  ConfirmText,
  ConfirmTitle,
  HeaderOutContainer,
  InfoBox,
  SolutionBox,
  SolutionText,
} from '../ReportDetail/styled/ReportDetail';
import { BanHistory } from './BanHistory';

export const UserDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState<ClientUser | null>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [modal, setModal] = useState<ConfirmModal>(
    actionConfirmation[EActionConfirm.UNBAN]
  );
  const selectedUser = useAppSelector<ClientUser | undefined>(
    getUserById(`${id}`)
  );
  const userRole = useAppSelector(getAdminRole);

  const isPreMediumScreen = useMediaQuery('(max-width: 1100px)');
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (id && !user && loadingUser) {
      if (selectedUser) {
        setUser(selectedUser);
        setLoadingUser(false);
      } else {
        dispatch(getUserByIdAction(id))
          .unwrap()
          .then((currUser) => {
            setUser(currUser);
          })
          .catch((err) => {
            ToastService.error(err.message);
          })
          .finally(() => {
            setLoadingUser(false);
          });
      }
    }
  }, [dispatch, id, loadingUser, selectedUser, user]);

  const openConfirmModal = useCallback((action: EActionConfirm) => {
    setModal(actionConfirmation[action]);
    setIsOpenModal(true);
  }, []);

  const renderUserActionBox = useCallback(() => {
    if (user?.status === EClientUserStatus.ACTIVE) {
      return (
        <ActionBox>
          <ActionButton
            variant="contained"
            sx={{
              background: theme.palette.warning.dark,
              '&:hover': {
                background: theme.palette.warning.main,
              },
            }}
            onClick={() => openConfirmModal(EActionConfirm.BAN_APP)}
          >
            Ban From The App
          </ActionButton>
          <ActionButton
            variant="contained"
            color="error"
            disabled={userRole === EUserRole.MODERATOR}
            onClick={() => openConfirmModal(EActionConfirm.DELETE_USER)}
          >
            Delete User
          </ActionButton>
        </ActionBox>
      );
    } else {
      return (
        <ActionBox>
          <SolutionBox marginBottom="10px">
            <SolutionText>Banned from the App</SolutionText>
          </SolutionBox>
          <ActionButton
            variant="contained"
            color="primary"
            className={'full-size'}
            onClick={() => openConfirmModal(EActionConfirm.UNBAN)}
          >
            Unban
          </ActionButton>
          <ActionButton
            disabled={userRole === EUserRole.MODERATOR}
            variant="contained"
            color="error"
            className={'full-size'}
            onClick={() => openConfirmModal(EActionConfirm.DELETE_USER)}
          >
            Delete User
          </ActionButton>
        </ActionBox>
      );
    }
  }, [openConfirmModal, user?.status, userRole]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const modalClose = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  const banFromApp = useCallback(() => {
    if (user) {
      dispatch(banUserInApp(user.id))
        .unwrap()
        .then(() => {
          setUser(
            (userCurr) =>
              userCurr && { ...userCurr, status: EClientUserStatus.BAN }
          );
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    }
  }, [user, dispatch]);

  const deleteUserFromApp = useCallback(() => {
    if (user) {
      dispatch(deleteUser(user.id))
        .unwrap()
        .then(() => {
          ToastService.success('User successfuly deleted');
          navigate(ROUTES.DASHBOARD.USERS);
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    }
  }, [user, dispatch, navigate]);

  const unban = useCallback(() => {
    if (user) {
      dispatch(unbanUserInApp(user.id))
        .unwrap()
        .then(() => {
          setUser(
            (userCurr) =>
              userCurr && { ...userCurr, status: EClientUserStatus.ACTIVE }
          );
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    }
  }, [user, dispatch]);

  const confirmActionHandle = useCallback(
    (action: EActionConfirm) => {
      switch (action) {
        case EActionConfirm.BAN_APP: {
          banFromApp();
          break;
        }
        case EActionConfirm.UNBAN: {
          unban();
          break;
        }
        case EActionConfirm.DELETE_USER: {
          deleteUserFromApp();
          break;
        }
        default:
          break;
      }
      modalClose();
    },
    [modalClose, banFromApp, unban, deleteUserFromApp]
  );

  return (
    <Box>
      <Helmet>
        <title>User Details</title>
      </Helmet>
      {user ? (
        <HeaderOutContainer>
          <IconButton aria-label="back" onClick={goBack}>
            <ArrowBack />
          </IconButton>
          <Typography component="h5" fontSize={24} sx={{ marginX: '10px' }}>
            {user?.username}
          </Typography>
          {user ? (
            <StatusChip
              sx={{
                marginLeft: 0,
                backgroundColor: UserStatusColor[user.status],
              }}
              label={user.status}
            />
          ) : null}
        </HeaderOutContainer>
      ) : null}
      {user ? (
        <TableWrapper
          display="flex"
          padding="16px"
          flexWrap={isPreMediumScreen ? 'wrap' : 'nowrap'}
          justifyContent="space-between"
        >
          <InfoBox>
            <Box display="flex" mb={'20px'} flexWrap="wrap" gap="20px">
              <Box flex={1} display="flex" flexWrap={'wrap'} gap="20px">
                <ColumnDetail flex={1}>
                  <ColumnTitle>Name</ColumnTitle>
                  <Typography>
                    {user?.firstName && user?.lastName
                      ? `${user?.firstName} ${user?.lastName}`
                      : user?.firstName || ''}
                  </Typography>
                </ColumnDetail>
                {!!user?.mobilePhone && (
                  <ColumnDetail flex={1} minWidth="200px">
                    <ColumnTitle>Phone Number</ColumnTitle>
                    <Typography>{user.mobilePhone}</Typography>
                  </ColumnDetail>
                )}
              </Box>
              {!!user?.email && (
                <ColumnDetail flex={1} minWidth="200px">
                  <ColumnTitle>Email</ColumnTitle>
                  <Box
                    display="flex"
                    alignItems={'center'}
                    gap={'10px'}
                    width={'100%'}
                    flexWrap={isMobileScreen ? 'wrap' : 'nowrap'}
                  >
                    <Tooltip title={user.email}>
                      <TypographyElipsis>{user.email}</TypographyElipsis>
                    </Tooltip>
                    <StatusChip
                      sx={{ marginLeft: 0 }}
                      label={
                        user.isEmailVerified ? (
                          <Box display="flex" alignItems={'center'}>
                            <Check />
                            <Typography ml={1}>Verified</Typography>
                          </Box>
                        ) : (
                          <Box display="flex" alignItems={'center'}>
                            <Close />
                            <Typography ml={1}>Not verified</Typography>
                          </Box>
                        )
                      }
                      color={user.isEmailVerified ? 'success' : 'error'}
                    />
                  </Box>
                </ColumnDetail>
              )}
            </Box>
            <Box display="flex" flexWrap="wrap" gap="20px">
              <Box flex={1} display="flex" flexWrap="wrap" gap="20px">
                <ColumnDetail flex={1} minWidth="200px">
                  <ColumnTitle>Joined At</ColumnTitle>
                  <Typography>
                    {user.createdAt
                      ? format(parseISO(user.createdAt), 'dd/MM/yy')
                      : '--/--/--'}
                  </Typography>
                </ColumnDetail>
                <ColumnDetail flex={1} minWidth="120px">
                  <ColumnTitle>Region</ColumnTitle>
                  <TypographyElipsis>
                    {user?.lastLocationName || 'N/A'}
                  </TypographyElipsis>
                </ColumnDetail>
              </Box>
              <ColumnDetail flex={1} minWidth="120px">
                <ColumnTitle>User ID</ColumnTitle>
                <Typography>{user.id}</Typography>
              </ColumnDetail>
            </Box>
            <Divider sx={{ my: '20px' }} />
            <Box
              display="flex"
              flexWrap="wrap"
              gap="20px"
              mb={isPreMediumScreen ? '20px' : 0}
            >
              <ColumnDetail flex={1} minWidth="120px">
                <ColumnTitle>Geocaching account is associated</ColumnTitle>
                <StatusChip
                  sx={{ ml: 0 }}
                  label={
                    user?.isGeocachingConnected ? (
                      <Box display="flex" alignItems={'center'}>
                        <Check />
                        <Typography ml={1}>Yes</Typography>
                      </Box>
                    ) : (
                      <Box display="flex" alignItems={'center'}>
                        <Close />
                        <Typography ml={1}>No</Typography>
                      </Box>
                    )
                  }
                  color={user?.isGeocachingConnected ? 'success' : 'error'}
                />
              </ColumnDetail>
              {!!user?.geocachingUsername && (
                <ColumnDetail flex={1} minWidth="120px" height={'100%'}>
                  <ColumnTitle>Geocaching nickname</ColumnTitle>
                  <Tooltip title={user?.geocachingUsername}>
                    <TypographyElipsis
                      height={'32px'}
                      display={'flex'}
                      alignItems={'center'}
                      fontWeight={600}
                      color={theme.palette.primary.main}
                    >
                      {user?.geocachingUsername}
                    </TypographyElipsis>
                  </Tooltip>
                </ColumnDetail>
              )}
            </Box>
            {!!isPreMediumScreen && <Divider sx={{ my: '20px' }} />}
          </InfoBox>
          {renderUserActionBox()}
          <Modal isOpen={isOpenModal} hasCloseIcon onClose={modalClose}>
            <WarningBox>
              <img src={WarningSign} />
              <ConfirmTitle>{modal.title}</ConfirmTitle>
              <ConfirmText>{modal.text(user.username)}</ConfirmText>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CancelButton
                  className="cancel"
                  variant="contained"
                  onClick={() => modalClose()}
                >
                  Cancel
                </CancelButton>
                <ConfirmButton
                  variant="contained"
                  onClick={() => confirmActionHandle(modal.action)}
                  color={
                    modal.action === EActionConfirm.UNBAN ? 'primary' : 'error'
                  }
                >
                  {modal.activeButton}
                </ConfirmButton>
              </Box>
            </WarningBox>
          </Modal>
        </TableWrapper>
      ) : !loadingUser ? (
        <TableWrapper paddingTop="20px" paddingBottom="20px">
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            minHeight="60vh"
            padding="20px"
          >
            <img src={NoUser} />
            <Typography
              sx={{
                color: '#000',
                fontSize: '30px',
                fontWeight: '500',
                textTransform: 'uppercase',
                marginTop: '40px',
                marginBottom: '20px',
              }}
            >
              User is not found
            </Typography>
            <Typography
              sx={{
                color: '#000',
                fontSize: '24px',
                fontWeight: 400,
                marginBottom: '30px',
                opacity: '0.5',
              }}
            >
              It looks like this user no longer exists.
            </Typography>
            <Typography
              sx={{
                color: '#2196F3',
                fontSize: '24px',
                fontWeight: 400,
                cursor: 'pointer',
              }}
              onClick={goBack}
            >
              View users list
            </Typography>
          </Box>
        </TableWrapper>
      ) : (
        <GlobalSpinner />
      )}
      {user ? (
        <Box marginTop="20px">
          <BanHistory user={user} />
        </Box>
      ) : null}
      {user ? (
        <Box marginTop="20px">
          <ReportHistory historyType={EReportObject.USER} mainId={user.id} />
        </Box>
      ) : null}
    </Box>
  );
};
