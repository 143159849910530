import { AsyncThunk } from '@reduxjs/toolkit';

import { store } from '../store';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
export type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

export type ActionError = {
  error: string;
};

type ErrorInfo = {
  message: string;
  statusCode: number;
  stack: string;
};

export type ErrorDto = {
  error: ErrorInfo;
};

export enum EStatusCode {
  FORBIDDEN = 403,
}
