export enum EUserRole {
  ADMIN = 'admin',
  MODERATOR = 'moderator',
  SUPER_ADMIN = 'super-admin',
}

export enum EUserStatus {
  NEW = 'new',
  PENDING = 'pending',
  ACTIVE = 'active',
  SUSPEND = 'suspend',
}

export type User = {
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  role: EUserRole;
  createdAt: string;
  updatedAt: string;
  status: EUserStatus;
};

export type UserSetPassword = {
  password: string;
  repeatPassword: string;
};

export type UserLogin = {
  email: string;
  password: string;
};

export type UserPassvordRecovery = {
  email: string;
};

export type CreateAdminUser = {
  email: string;
  firstName: string;
  lastName: string;
  role: EUserRole | string;
  status?: string;
};

export type EditAdminUser = {
  id: string;
  data: CreateAdminUser;
};

export type UserResetPassword = {
  newPassword: string;
  resetToken: string;
};

export type UserAcceptInvite = {
  invitationToken: string;
  newPassword: string;
};

export type AdminUserName = {
  firstName: string;
  lastName: string;
};

export type AdminUserPassword = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};
